import React, { useState } from "react";
import Header from "../components/header/Header";
import { Link, useNavigate } from "react-router-dom";

const cards = [
  {
    id: 1,
    title: "Generative AI Application",
    text: "helps businesses create personalised content uch as email campaigns, creative briefs, and online ads.",
    cardImg: "assets/home/home1.svg",
    cardLink: "/home",
  },
  {
    id: 2,
    title: "Generative AI Application",
    text: "helps businesses create personalised content uch as email campaigns, creative briefs, and online ads.",
    cardImg: "assets/home/home2.svg",
    cardLink: "/indulekha",
  },
  {
    id: 3,
    title: "Generative AI Application",
    text: "helps businesses create personalised content uch as email campaigns, creative briefs, and online ads.",
    cardImg: "assets/home/home3.svg",
    cardLink: "/tech",
  },
  {
    id: 4,
    title: "Generative AI Application",
    text: "helps businesses create personalised content uch as email campaigns, creative briefs, and online ads.",
    cardImg: "assets/home/home4.svg",
    cardLink: "/home",
  },
];

function Home1() {
  const [selectH, setSelectH] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  const navigate = useNavigate();

  const handleNext = () => {
    if (currentIndex < cards.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <>
      <Header />
      <div
        
        className="home-hero pt-[84px]   ">
        {selectH === "" && (
          <div className="flex sm:max-w-full h-[332px] max-w-[300px] w-full m-auto flex-col items-center justify-center">
          <div>
            <img src="assets/home/item6.svg" className="h-[280px]" alt="" />
          </div>
            <p
              style={{ fontFamily: "Satoshi" }}
              className="font-[400] text-center text-[20px] sm:py-0 py-[50px] leading-[30px] text-[#FFFFFF]"
            >
              Hey 👋🏼, I'm Mihir Vaidya, A Product Designer.
            </p>
            <p className="font-Caveat font-[400] text-[18px] leading-[16px] text-[#B9BCDD] pt-[8px]">
              ...with over 2+ years of expertise.
            </p>
            <div className="flex sm:hidden items-end gap-[8px]">
              <p
                style={{ fontFamily: "Satoshi" }}
                className="font-[700] text-[16px] leading-[14px] text-[#FFFFFF] pt-[56px] "
              >
                Scroll Doown...
              </p>{" "}
              <img src="assets/home/down-arrow.svg" alt="" />
            </div>
          </div>
        )}
        {selectH === "1" && (
          <div className="bg-[#20232D66] max-w-[1344px] py-[30px] w-full m-auto rounded-[30px] border border-[#393B41] px-[62px]">
            <div className="grid grid-cols-2 items-center gap-[42px]">
              <div className="max-w-[589px]">
                <div className="flex items-center mb-[48px] gap-[14px]">
                  <img src="assets/home/i.svg" alt="" />
                  <p className="font-satoshi text-white font-[700] text-[16px] leading-[21.6px]">
                    Typeface
                  </p>
                </div>
                <p
                  style={{ fontFamily: "ClashDisplay" }}
                  className="font-[700] pb-[16px] text-[32px] leading-[39.36px] text-[#FFFFFF]"
                >
                  Generative AI Application
                </p>
                <p
                  style={{ fontFamily: "Satoshi" }}
                  className="font-[400] max-w-[419px] w-full pb-[32px] text-[16px] leading-[22.4px] text-[#FFFFFF]"
                >
                  helps businesses create personalised content uch as email
                  campaigns, creative briefs, and online ads.
                </p>
                <div className="flex gap-[12px] items-center">
                  <p
                    style={{ fontFamily: "Satoshi" }}
                    className="text-[24px] font-[700] leading-[32px] text-[#FFFFFF]"
                  >
                    View Project
                  </p>
                  <img src="assets/home/arrow-right.svg" alt="" />
                </div>
              </div>
              <div className="">
                <img src="assets/home/a1.svg" className="h-[270px]" alt="" />
              </div>
            </div>
          </div>
        )}
        {selectH === "2" && (
          <div className="bg-[#20232D66] max-w-[1344px] py-[30px] w-full m-auto rounded-[30px] border border-[#393B41] px-[62px]">
            <div className="grid grid-cols-2 items-center gap-[42px]">
              <div className="max-w-[589px]">
                <div className="mb-[63px]">
                  <img src="assets/home/a2.svg" alt="" />
                </div>
                <p
                  style={{ fontFamily: "ClashDisplay" }}
                  className="font-[700] pb-[16px] text-[32px] leading-[39.36px] text-[#FFFFFF]"
                >
                  Generative AI Application
                </p>
                <p
                  style={{ fontFamily: "Satoshi" }}
                  className="font-[400] max-w-[419px] w-full pb-[32px] text-[16px] leading-[22.4px] text-[#FFFFFF]"
                >
                  helps businesses create personalised content uch as email
                  campaigns, creative briefs, and online ads.
                </p>
                <div className="flex gap-[12px] items-center">
                  <p
                    style={{ fontFamily: "Satoshi" }}
                    className="text-[24px] font-[700] leading-[32px] text-[#FFFFFF]"
                  >
                    View Project
                  </p>
                  <img src="assets/home/arrow-right.svg" alt="" />
                </div>
              </div>
              <div className="">
                <img src="assets/home/a3.svg" className="h-[270px]" alt="" />
              </div>
            </div>
          </div>
        )}
        {selectH === "3" && (
          <div className="bg-[#20232D66] max-w-[1344px] py-[30px] w-full m-auto rounded-[30px] border border-[#393B41] px-[62px]">
            <div className="grid grid-cols-2 items-center gap-[42px]">
              <div className="max-w-[589px]">
                <div className="mb-[63px]">
                  <img src="assets/home/logo1.svg" alt="" />
                </div>
                <p
                  style={{ fontFamily: "ClashDisplay" }}
                  className="font-[700] pb-[16px] text-[32px] leading-[39.36px] text-[#FFFFFF]"
                >
                  Generative AI Application
                </p>
                <p
                  style={{ fontFamily: "Satoshi" }}
                  className="font-[400] max-w-[419px] w-full pb-[32px] text-[16px] leading-[22.4px] text-[#FFFFFF]"
                >
                  helps businesses create personalised content uch as email
                  campaigns, creative briefs, and online ads.
                </p>
                <div className="flex gap-[12px] items-center">
                  <p
                    style={{ fontFamily: "Satoshi" }}
                    className="text-[24px] font-[700] leading-[32px] text-[#FFFFFF]"
                  >
                    View Project
                  </p>
                  <img src="assets/home/arrow-right.svg" alt="" />
                </div>
              </div>
              <div className="">
                <img src="assets/home/a4.svg" className="h-[270px]" alt="" />
              </div>
            </div>
          </div>
        )}
        {selectH === "4" && (
          <div className="bg-[#20232D66] max-w-[1344px] py-[30px] w-full m-auto rounded-[30px] border border-[#393B41] px-[62px]">
            <div className="grid grid-cols-2 items-center gap-[42px]">
              <div className="max-w-[589px]">
                <div className="mb-[63px]">
                  <img src="assets/home/logo1.svg" alt="" />
                </div>
                <p
                  style={{ fontFamily: "ClashDisplay" }}
                  className="font-[700] pb-[16px] text-[32px] leading-[39.36px] text-[#FFFFFF]"
                >
                  Generative AI Application
                </p>
                <p
                  style={{ fontFamily: "Satoshi" }}
                  className="font-[400] max-w-[419px] w-full pb-[32px] text-[16px] leading-[22.4px] text-[#FFFFFF]"
                >
                  helps businesses create personalised content uch as email
                  campaigns, creative briefs, and online ads.
                </p>
                <div className="flex gap-[12px] items-center">
                  <p
                    style={{ fontFamily: "Satoshi" }}
                    className="text-[24px] font-[700] leading-[32px] text-[#FFFFFF]"
                  >
                    View Project
                  </p>
                  <img src="assets/home/arrow-right.svg" alt="" />
                </div>
              </div>
              <div className="">
                <img src="assets/home/a4.svg" className="h-[270px]" alt="" />
              </div>
            </div>
          </div>
        )}
        <div
          className="mt-[30px] pl-[48px] sm:block hidden">
          <h6
            style={{ fontFamily: "Satoshi" }}
            className="font-[400] pb-[12px] text-[24px] leading-[22.08px] text-[#D4D4E2]"
          >
            Selected Projects
          </h6>
          {/* <p className="font-satoshi font-[700] leading-[14.72px] pb-[32px] flex gap-[10px] items-center text-[16px] text-[#FFFFFF]">
            View More Project <img src="assets/home/right-arrow.svg" alt="" />
          </p> */}
          <div
          
            style={{

              width: "95%",
              height: "340px",
              overflow: "hidden",
              marginTop :"99px"
            }}
            className="grid sm:grid-cols-4 grid-cols-1 gap-[78px] pr-[48px] pb-[50px]">
            <div
              className={`duration-300 transition-all ${
                selectH === "1"
                  ? "rotate-[0deg]"
                  : "rotate-[-5deg]"
              }`}
              onMouseEnter={() => setSelectH("1")}
              onMouseLeave={() => setSelectH("")}
            >
              <Link to="/home">
                <img src="assets/home/home1.svg" className="w-full h-[389px]" alt="" />
              </Link>
            </div>
            <div
              className={`duration-300 transition-all ${
                selectH === "2"
                  ? "rotate-[0deg]"
                  : "rotate-[5deg]"
              }`}
              onMouseEnter={() => setSelectH("2")}
              onMouseLeave={() => setSelectH("")}
            >
              <Link to="/indulekha">
                <img src="assets/home/home2.svg" className="w-full h-[389px]" alt="" />
              </Link>
            </div>
            <div
              className={`duration-300 transition-all ${
                selectH === "3"
                  ? "rotate-[0deg]"
                  : "rotate-[-5deg]"
              }`}
              onMouseEnter={() => setSelectH("3")}
              onMouseLeave={() => setSelectH("")}
            >
              <Link to="/tech">
                <img src="assets/home/home3.svg" className="w-full h-[389px]" alt="" />
              </Link>
            </div>
            <div
              className={`duration-300 transition-all ${
                selectH === "4"
                  ? "rotate-[0deg]"
                  : "rotate-[5deg]"
              }`}
              onMouseEnter={() => setSelectH("4")}
              onMouseLeave={() => setSelectH("")}
            >
              <Link to="/indulekha">
                <img src="assets/home/home4.svg" className="w-full h-[389px]" alt="" />
              </Link>
            </div>
          </div>
        </div>
        <div className="sm:hidden overflow-hidden mt-[200px] block">
          <p
            style={{ fontFamily: "Satoshi" }}
            className="font-[500] text-[14px] leading-[12.88px] text-center text-[#FFFFFF] pb-[32px]"
          >
            Selected Projects 
            <span className="text-[#AD5BFF]">
              &nbsp;
              {`(${cards[currentIndex].id}/4)`}
            </span>
          </p>
          <div className="max-w-[358px] m-auto">
            <h6
              style={{ fontFamily: "ClashDisplay" }}
              className="text-[#FFFFFF] pb-[20px] font-[600] text-[24px] leading-[29.52px] text-center"
            >
              Generative <span className="text-[#FF3D55]">AI Application</span>
            </h6>
            <p
              style={{ fontFamily: "Satoshi" }}
              className="font-[400] text-[14px] leading-[19.6px] text-center text-[#FFFFFF] pb-[40px]"
            >
              {cards[currentIndex].text}
            </p>
          </div>
          <div
            className={`duration-300 transition-all ${
              selectH === "8"
                ? "rotate-[0deg] -translate-y-6"
                : "rotate-[-5deg]"
            }`}
            onMouseEnter={() => setSelectH("8")}
            onMouseLeave={() => setSelectH("")}
          >
            <Link to={cards[currentIndex].cardLink}>
              <img
                src={cards[currentIndex].cardImg}
                className="h-[268px] w-[204px] m-auto"
                alt=""
              />
            </Link>
          </div>
          <div className="flex mt-[47px] pb-[33px] justify-between px-[16px]">
            <button onClick={handlePrev}>
              <img src="assets/left-arrow.svg" alt="" />
            </button>
            <button
              onClick={() => navigate(cards[currentIndex].cardLink)}
              style={{ fontFamily: "Satoshi" }}
              className="flex items-center justify-center gap-[8px] text-[13.5px] leading-[18.23px] text-[#FFFFFF] rounded-[24px] font-[700] border border-[#444A5B] bg-[#2E313ABF] py-[8px] px-[12px]"
            >
              View Project <img src="assets/arrow.svg" alt="" />
            </button>
            <button onClick={handleNext}>
              <img src="assets/right-arrow.svg" alt="" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home1;
