import React, { useState } from "react";
import "./header.css";
import { Link } from "react-router-dom";

function Header() {
  const [dropdown, setDropdown] = useState(false);
  function handleDropdown() {
    setDropdown(!dropdown);
  }

  return (
    <>
      <div className="flex w-full justify-between header-blur z-50 fixed top-0 items-center sm:px-[48px] px-[14px] bg-[#19171740] py-[12px]">
        <Link to="/">
          <img src="assets/home/logo.svg" alt="" />
        </Link>
        <div className="flex gap-[24px] items-center">
          <button
            style={{ fontFamily: "Satoshi" }}
            className="sm:flex hidden font-[700] text-[16px] leading-[21.6px] text-[#FFFFFF] items-center bg-[#2E313A] px-[16px] py-[12px] rounded-[16px] gap-[8px]"
          >
            <img src="assets/home/active.svg" alt="" />
            Hire Me
            <img src="assets/home/down-arrow.svg" alt="" />
          </button>
          <button onClick={() => handleDropdown()}>
            <img src="assets/home/bras.svg" alt="" />
          </button>
        </div>
      </div>
      <div
        className={`fixed right-0 left-0 navbar-item bg-[#4B009699] duration-300 ease-in ${
          dropdown ? "top-[0px] sm:h-[685px] h-auto" : "top-[-810px] h-[0px]"
        }`}
      >
        <div className="flex items-center justify-between py-[12px]">
          <Link to="/">
            <img src="assets/home/logo.svg" alt="" />
          </Link>
          <div className="flex gap-[24px] items-center py-[7px]">
            <button
              style={{ fontFamily: "Satoshi" }}
              className="font-[400] flex gap-[16px] items-center sm:text-[24px] text-[16px] sm:leading-[33.6px] leading-[24px] text-[#FFFFFF]"
              onClick={() => handleDropdown()}
            >
              Close
              <img
                src="assets/close.svg"
                className="sm:h-[24px] h-[16px] sm:w-[24px] w-[16px]"
                alt=""
              />
            </button>
          </div>
        </div>
        <div className="grid sm:grid-cols-2 grid-cols-1 sm:gap-[88px] gap-[40px] sm:mt-[56px] mt-[20px]">
          <div className="flex flex-col sm:gap-[24px] gap-[14px]">
            <Link
              style={{ fontFamily: "ClashDisplay" }}
              className="text-[#FFFFFF] text-clash font-[700] sm:text-[42px] text-[24px] sm:leading-[58.8px] leading-[40px]"
              to="/"
            >
              Home
            </Link>
            <Link
              style={{ fontFamily: "ClashDisplay" }}
              className="text-[#FFFFFF] text-clash font-[400] sm:text-[42px] text-[24px] sm:leading-[58.8px] leading-[40px]"
              to="/"
            >
              Work
            </Link>
            <Link
              style={{ fontFamily: "ClashDisplay" }}
              className="text-[#FFFFFF] text-clash font-[400] sm:text-[42px] text-[24px] sm:leading-[58.8px] leading-[40px]"
              to="/"
            >
              Blog
            </Link>
            <Link
              style={{ fontFamily: "ClashDisplay" }}
              className="text-[#FFFFFF] text-clash font-[400] sm:text-[42px] text-[24px] sm:leading-[58.8px] leading-[40px]"
              to="/"
            >
              About Me
            </Link>
          </div>
          <div>
            <p
              style={{ fontFamily: "Satoshi" }}
              className="uppercase font-[500] text-[16px] leading-[20.8px] text-[#FFFFFF] pb-[16px]"
            >
              Let’s Connect...
            </p>
            <p
              style={{ fontFamily: "Satoshi" }}
              className="font-[400] sm:text-[42px] text-[30px] sm:leading-[54.6px] leading-[40px] text-[#FFFFFF] sm:pb-[32px] pb-[20px]"
            >
              How Can I Help You?
            </p>
            <button
              style={{ fontFamily: "Satoshi", }}
              className="flex sm:py-[15px] py-[10px] sm:px-[30px] px-[16px] rounded-[24px] border border-[#FFFFFF] text-[#FFFFFF] font-[700] text-[13.5px] leading-[18.23px] items-center gap-[8px] "
            >
              <a
                href="/assets/pdf/Mihir Vaidya UX Designer CV.pdf" // Relative path to the file in the public folder
                download // Suggests the browser to download the file instead of opening it
                style={{ textDecoration: "none", color: "inherit",display : 'flex' ,justifyContent :'center', alignItems : "center" }} // Inherit button styles
              >
                Download Resume
                <img
                  style={{
                    marginLeft :"10px"
                  }}
                  src="assets/home/right-arrow.svg" alt="" />
              </a>
            </button>
            <button
              style={{ fontFamily: "Satoshi" }}
              className="flex sm:py-[15px] py-[10px] sm:px-[30px] px-[16px] sm:mt-[24px] mt-[16px] rounded-[24px] border border-[#FFFFFF] text-[#FFFFFF] font-[700] text-[13.5px] leading-[18.23px] items-center gap-[8px]"
            >
              Contact ME!
              <img src="assets/home/right-arrow.svg" alt="" />
            </button>
          </div>
        </div>
        {/* <div
          className={`flexitems-start px-[14px] flex-col`}
        >
          <Link className="text-[#FFF]" to="/">
            Home
          </Link>
          <Link className="text-[#FFF]" to="/indulekha">
            Indulekha
          </Link>
          <Link className="text-[#FFF]" to="/tech">
            Tech
          </Link>
        </div> */}
      </div>
    </>
  );
}

export default Header;
